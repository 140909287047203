<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ lineId ? 'Edit' : 'New' }} research line
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to research lines"
              @click="$router.push({name: 'researcher.research-lines.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="loggedUser.roles.includes('super-admin')"
              class="btn btn-icon btn-flat-secondary"
              @click="$store.dispatch('modals/toggleRequestModal', 'ResearchLine')"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="line" />

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Basic information</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="mb-1 form-group required">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['basic_information.title'] }}</label>
                        <input
                          v-model="line.title"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-10">
                      <div class="mb-1 form-group required">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['basic_information.area'] }}</label>
                        <v-select
                          v-model="line.area"
                          label="name"
                          :options="areas"
                          :get-option-key="option => option.id"
                          :reduce="e => e.id"
                        />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1 form-group required">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['basic_information.order'] }}</label>
                        <input
                          v-model="line.order"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="mb-1 form-group required">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['basic_information.description'] }}</label>
                        <quill-editor
                          v-model="line.body"
                        />
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="row">
                    <div class="col">
                      <div class="form-check form-check-success form-switch d-flex align-items-center">
                        <input
                          id=""
                          v-model="line.publish_web"
                          type="checkbox"
                          class="form-check-input"
                        >
                        <label
                          class="form-check-label"
                          for=""
                        ><small class="ms-1">{{ labels['basic_information.active'] }} </small></label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <AddImage
              :title="'Images'"
              :images="line.images"
              @saveAnswer="saveImages"
            />

            <AddFile
              :id="6"
              :title="'Files'"
              :type="3"
              :files="line.files"
              @saveAnswer="saveFiles"
            />

            <AttachedForm
              :type="'ResearchLine'"
              :model-id="lineId"
            />
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!loading"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" /> <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-if="loading"
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import AddFile from '../../admin/senior-call/components/AddFile.vue'
import AddImage from '../../partials/components/AddImage.vue'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'
import AttachedForm from '../../forms/partials/AttachedForm.vue'

export default {
  components: {
    AddFile,
    AddImage,
    SearchResearcher,
    vSelect,
    AttachedForm,
  },
  data() {
    return {
      lineId: this.$route.params.id,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      areas: 'areas/areas',
      line: 'lines/line',
      users: 'users/aspirants',
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/itemLabels',
      attached: 'requestForms/attached',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'my_profile.research_lines')

    if (this.lineId) {
      await this.$store.dispatch('lines/fetchLine', this.lineId)
    } else {
      await this.$store.dispatch('lines/cleanLine')
    }
    if (this.areas.length === 0) {
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }
    if (this.users.length === 0) {
      this.$store.dispatch('users/fetchAspirants')
    }
  },
  methods: {
    saveImages(imgs) {
      this.line.images_new = imgs.data
    },
    saveFiles(files) {
      this.line.files_new = files.data
    },
    async save() {
      this.loading = true
      this.line.area_id = this.line.area && this.line.area.id ? this.line.area.id : this.line.area
      this.line.attached_form = this.attached
      if (!this.line.area_id) {
        Vue.swal('Error - Required information missing', 'Area field - Required', 'error')
        this.loading = false
        return
      }

      try {
        if (this.lineId) {
          await this.$store.dispatch('lines/update', { lineId: this.line.id, data: this.line })
        } else {
          await this.$store.dispatch('lines/create', this.line)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.line.id) {
        Vue.swal('Research line updated successfully!', '', 'success').then(() => {
          this.$router.push({ name: 'researcher.research-lines.index' })
        })
      } else {
        // Vue.swal
      }

      this.loading = false
    },
  },
}
</script>
